import React, { FunctionComponent } from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import { Outlet } from 'react-router-dom';
import useWindowDimensions from '../components/useWindowDimensions';

interface LayoutDefaultProps {}

const LayoutDefault: FunctionComponent<LayoutDefaultProps> = () => {
  const windowDimensions = useWindowDimensions();
  return (
    <>
      <Header navPosition="right" className="reveal-from-bottom" />
      <main className={windowDimensions.width > 1300 ? 'site-content' : ''}>
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

export default LayoutDefault;
