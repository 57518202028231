import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
// import LogoFooter from './partials/LogoFooter';
import FooterNav from './partials/FooterNav';
import FooterSocial from './partials/FooterSocial-1';

const defaultProps = {
  topOuterDivider: false,
  topDivider: false
};

interface FooterProps {
  className?: string;
  topOuterDivider?: boolean;
  topDivider?: boolean;
}

const Footer: FunctionComponent<FooterProps> = ({
  className,
  topOuterDivider,
  topDivider,
  ...props
}) => {
  const classes = classNames('site-footer', topOuterDivider && 'has-top-divider', className);
  return (
    <footer {...props} className={classes}>
      <div className="container pt-32 pb-32">
        <div className={classNames(topDivider && 'has-top-divider')}>
          {/* <div className="footer-top space-between text-xxs center-content-mobile">
            <LogoFooter />
          </div> */}
          <div className="footer-bottom space-between text-xxs pt-32 pb-32">
            <FooterNav />
          </div>
          <div id="footerBlock" className="footer-bottom navPosition-center text-xxs">
            <div className="footer-copyright-row">
              <div>
                <span>
                  <b>Phone:</b>{' '}
                  <a className={'link-1'} href="tel:8329812306" rel="noopener noreferrer">
                    832-981-2306
                  </a>
                  , Monday to Friday, 9am - 5pm EST <br />
                  <b>Email:</b>{' '}
                  <a
                    className={'link-1'}
                    href="mailto:support@opencashbrokerage.com"
                    rel="noopener noreferrer">
                    support@opencashbrokerage.com
                  </a>
                </span>
              </div>
              <FooterSocial />
            </div>
            <br />
            <br />
            Brokerage services are provided by Opencash Securities LLC, member of{' '}
            <a
              href={'https://www.finra.org/'}
              rel={'noopener noreferrer'}
              className={'link-1'}
              target={'_blank'}>
              FINRA
            </a>{' '}
            and{' '}
            <a
              href={'https://www.sipc.org/'}
              rel={'noopener noreferrer'}
              className={'link-1'}
              target={'_blank'}>
              SIPC
            </a>
            . Options involve risk and are not suitable for all investors. Please review{' '}
            <a
              href={
                'https://www.theocc.com/Company-Information/Documents-and-Archives/Options-Disclosure-Document'
              }
              className={'link-1'}
              rel={'noopener noreferrer'}
              target={'_blank'}>
              Characteristics and Risks of Standardized Options
            </a>{' '}
            prior to engaging in options trading. Opencash Securities LLC does not provide
            investment advice. Please consult with investment, tax, or legal professionals before
            making any investment decisions. All investments involve risks, including the possible
            loss of capital. Check the background of this investment professional on{' '}
            <a
              href={'https://brokercheck.finra.org/firm/summary/317007'}
              rel={'noopener noreferrer'}
              className={'link-1'}
              target={'_blank'}>
              BrokerCheck
            </a>
            . Opencash Securities LLC is a wholly-owned subsidiary of Opencash Finance, Inc. <br />
            <br />
            Information provided by Opencash Support is for informational and general educational
            purposes only and is not investment or financial advice.
            <br />
            1120 NASA Parkway, Suite 220C, Houston, TX 77058. <br /> <br />
            &copy; {new Date().getFullYear()} Opencash Finance, Inc. All Rights Reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};
Footer.defaultProps = defaultProps;
export default Footer;
