import React, { FunctionComponent, lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

// layouts
import LayoutDefault from './layouts/LayoutDefault';
import { Container, Spinner } from 'react-bootstrap';

// views
// import Home from './views/Home';
// import ThankYou from './views/ThankYou';
// import NftExplorer from './views/NftExplorer';
const Home = lazy(() => import('./views/Home'));
const ThankYou = lazy(() => import('./views/ThankYou'));
const NftExplorer = lazy(() => import('./views/NftExplorer'));
const UserManagement = lazy(() => import('./views/UserManagement'));

interface MainProps {}

const Main: FunctionComponent<MainProps> = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="google-site-verification"
          content="bSPqvaFKATKmNtH92dgLhpJjtoXIoKbfkS-j9-fHtlI"
        />
        <link rel="icon" href="images/favicon.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <title>Opencash | The easiest way to add ACH payments to your product</title>
        <meta
          name="description"
          content="Opencash is the easiest way to add ACH payments to your product. Start processing ACH transfers in a few hours with our end-to-end solution. With built-in fraud detection and rules engine, you are protected against fraudulent transactions and lost funds."
        />
        <meta
          name="keywords"
          content="commission free trading, commission free options, commission free crypto, free crypto transfer, p2p, borrow against crypto, New, Crypto, Stock, Commission-free, options"
        />
      </Helmet>
      <Suspense
        fallback={
          <Container id="spinnerContainer">
            <Spinner id="spinnerLoading" animation={'border'} />
          </Container>
        }>
        <Routes>
          <Route element={<LayoutDefault />}>
            <Route path="/" element={<Home />} />
            <Route path="/thankyou" element={<ThankYou />} />
            <Route path="/nftexplorer" element={<NftExplorer />} />
            <Route path="userMangement" element={<UserManagement />} />
            <Route path="*" element={<Home />} />
          </Route>
        </Routes>
      </Suspense>
    </HelmetProvider>
  );
};

export default Main;
