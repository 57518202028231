import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
// import { Link } from 'react-router-dom';
import LogoHeader from './partials/LogoHeader';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPhone } from '@fortawesome/free-solid-svg-icons';

const defaultProps = {
  active: false,
  navPosition: '',
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false
};

interface HeaderProps {
  className?: string;
  active?: boolean;
  navPosition?: string;
  hideNav?: boolean;
  hideSignin?: boolean;
  bottomOuterDivider?: boolean;
  bottomDivider?: boolean;
}

const Header: FunctionComponent<HeaderProps> = ({
  className,
  active,
  //navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  ...props
}) => {
  const [isActive, setIsActive] = useState(false);
  isActive
    ? document.body.classList.add('off-nav-is-active')
    : document.body.classList.remove('off-nav-is-active');
  // const navInput = isActive ? classNames(('px')) : '';
  const nav = useRef<any>(null);
  const hamburger = useRef<any>(null);

  const openMenu = () => {
    nav.current.style.maxHeight = nav.current.scrollHeight + 'px';
    setIsActive(true);
    console.log('open menu');
  };

  const closeMenu = () => {
    nav.current && (nav.current.style.maxHeight = null);
    setIsActive(false);
    console.log('close menu');
  };

  const keyPress = (e: any) => {
    isActive && e.keyCode === 27 && closeMenu();
  };

  const clickOutside = (e: any) => {
    if (!nav.current) return;
    if (!isActive || nav.current.contains(e.target) || e.target === hamburger.current) return;
    console.log('click outside');
    closeMenu();
  };
  const classes = classNames('site-header', bottomOuterDivider && 'has-bottom-divider', className);

  useEffect(() => {
    active && openMenu();
    document.addEventListener('keydown', keyPress);
    document.addEventListener('click', clickOutside);
  }, []);

  useEffect(() => {
    // componentWillUnmount
    return () => {
      document.removeEventListener('keydown', keyPress);
      document.addEventListener('click', clickOutside);
    };
  }, [isActive]);

  return (
    <header {...props} className={classes}>
      <div className="container">
        <div className={classNames('site-header-inner', bottomDivider && 'has-bottom-divider')}>
          <LogoHeader />
          {!hideNav && (
            <React.Fragment>
              <button
                ref={hamburger}
                className="header-nav-toggle"
                onClick={isActive ? closeMenu : openMenu}>
                <span className="screen-reader">Menu</span>
                <span className="hamburger">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <nav ref={nav} className={classNames('header-nav px', isActive && 'is-active')}>
                <div className="header-nav-inner">
                  {/*<ul*/}
                  {/*  className={classNames(*/}
                  {/*    'list-reset text-xxs',*/}
                  {/*    navPosition && `header-nav-${navPosition}`*/}
                  {/*  )}>*/}
                  {/*  <li>*/}
                  {/*    <Link id="headerNavLink" to="/nftexplorer/" onClick={closeMenu}>*/}
                  {/*      NFT Explorer*/}
                  {/*    </Link>*/}
                  {/*  </li>*/}
                  {/*</ul>*/}
                  {/*<ul*/}
                  {/*  className={classNames(*/}
                  {/*    'list-reset text-xs',*/}
                  {/*    navPosition && `header-nav-${navPosition}`*/}
                  {/*  )}>*/}
                  {/*  <FontAwesomeIcon icon={faPhone} />*/}
                  {/*  <li>*/}
                  {/*    <a id="headerPhoneLink" href="tel:8329812306" rel="noopener noreferrer">*/}
                  {/*      832-981-2306*/}
                  {/*    </a>*/}
                  {/*  </li>*/}
                  {/*</ul>*/}
                  {/*<ul*/}
                  {/*  className={classNames(*/}
                  {/*    'list-reset text-xs',*/}
                  {/*    navPosition && `header-nav-${navPosition}`*/}
                  {/*  )}>*/}
                  {/*  <li>*/}
                  {/*    <a*/}
                  {/*      id="headerNavLink"*/}
                  {/*      href="https://support.opencash.com/article/31-about-opencash"*/}
                  {/*      target="_blank"*/}
                  {/*      rel="noopener noreferrer">*/}
                  {/*      About*/}
                  {/*    </a>*/}
                  {/*  </li>*/}
                  {/*</ul>*/}
                  {/*<ul*/}
                  {/*  className={classNames(*/}
                  {/*    'list-reset text-xs',*/}
                  {/*    navPosition && `header-nav-${navPosition}`*/}
                  {/*  )}>*/}
                  {/*  <li>*/}
                  {/*    <a*/}
                  {/*      id="headerNavLink"*/}
                  {/*      href="https://blog.opencash.com"*/}
                  {/*      target="_blank"*/}
                  {/*      rel="noopener noreferrer">*/}
                  {/*      Blog*/}
                  {/*    </a>*/}
                  {/*  </li>*/}
                  {/*</ul>*/}
                  {/*<ul*/}
                  {/*  className={classNames(*/}
                  {/*    'list-reset text-xs',*/}
                  {/*    navPosition && `header-nav-${navPosition}`*/}
                  {/*  )}>*/}
                  {/*  <li>*/}
                  {/*    <a*/}
                  {/*      id="headerNavLink"*/}
                  {/*      href="https://support.opencash.com/"*/}
                  {/*      target="_blank"*/}
                  {/*      rel="noopener noreferrer">*/}
                  {/*      Support*/}
                  {/*    </a>*/}
                  {/*  </li>*/}
                  {/*</ul>*/}
                  {!hideSignin && (
                    <ul className="list-reset header-nav-right">
                      {/* <li>
                        <Link
                          to="/signup/"
                          className="button button-wide-mobile button-sm"
                          onClick={closeMenu}>
                          Sign Up
                        </Link>
                      </li> */}
                    </ul>
                  )}
                </div>
              </nav>
            </React.Fragment>
          )}
        </div>
      </div>
    </header>
  );
};
Header.defaultProps = defaultProps;
export default Header;
