import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import LogoFooter from './LogoFooter';
// import { Link } from 'react-router-dom';

interface FooterNavProps {
  className?: string;
}

const GET_STARTED_OPTIONS = [
  // {
  //   title: 'Fee Schedule',
  //   link: 'https://opencash-disclosures.s3.amazonaws.com/OCS+Fee+Schedule.pdf'
  // },
  // { title: 'Official Rules', link: 'https://support.opencash.com/article/89-official-rules' },
  { title: 'Support', link: 'https://support.opencash.com/' },
  { title: 'FAQ', link: 'https://support.opencash.com/collection/11-faqs' }
];

const LEGAL_OPTIONS = [
  {
    title: 'Privacy',
    link: 'https://support.opencash.com/article/65-privacy-policy'
  },
  // { title: 'Disclosures', link: 'https://support.opencash.com/collection/67-disclosure-library' },
  {
    title: 'Form CRS',
    link: 'https://support.opencash.com/article/71-ocs-customer-relationship-summary'
  }
  // {
  //   title: 'Business Continuity Plan',
  //   link: 'https://opencash-disclosures.s3.amazonaws.com/OCS+Business+Continuity+Plan+Disclosure.pdf'
  // }
];

const COMPANY_OPTIONS = [
  {
    title: 'About Us',
    link: 'https://support.opencash.com/article/31-about-opencash'
  },
  { title: 'Blog', link: 'https://blog.opencash.com/' }
  // { title: 'Roadmap', link: 'https://app.loopedin.io/opencash#/roadmap' }
];

const FooterNav: FunctionComponent<FooterNavProps> = ({ className, ...props }) => {
  const classes = classNames('footer-nav col-md-12 footer-hr', className);

  const renderItem = (item: { title: string; link: string }, index: any) => {
    return (
      <li key={String(index)} className="nav-item mb-2">
        <a
          className="nav-link p-0 text-muted"
          href={item?.link}
          target="_blank"
          rel="noopener noreferrer">
          {item?.title}
        </a>
      </li>
    );
  };

  return (
    <div {...props} className={classes}>
      <div className="row">
        <div className="col-md-3 mb-5">
          <LogoFooter />
        </div>
        <div className="col-md-3 mb-3">
          <span>Get Started</span>
          <ul className="list-reset">{GET_STARTED_OPTIONS?.map(renderItem)}</ul>
        </div>
        <div className="col-md-3 mb-3">
          <span>Company</span>
          <ul className="list-reset">{COMPANY_OPTIONS?.map(renderItem)}</ul>
        </div>
        <div className="col-md-3 mb-3">
          <span>Legal</span>
          <ul className="list-reset">{LEGAL_OPTIONS?.map(renderItem)}</ul>
        </div>
      </div>
    </div>
  );
};

export default FooterNav;
