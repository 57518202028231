import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

interface LogoProps {
  className?: string;
}

const LogoFooter: FunctionComponent<LogoProps> = ({ className, ...props }) => {
  const classes = classNames('brand', className);
  return (
    <div {...props} className={classes}>
      <h1 className="m-0">
        <Link to="/">
          <img src="/images/opencash-logo-black.svg" alt="opencash logo" width={142} height={32} />
        </Link>
      </h1>
    </div>
  );
};

export default LogoFooter;
